<template>
  <div>
    <div v-if="!loading && series.length > 0 ">
      <vue-apex-charts
          ref="platformsBarChart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
      />
    </div>
    <div v-else-if="series.length === 0" style="margin-top: 150px; text-align: center">
      <b-alert show variant="warning" class="mt-2">
        {{$t('nodatafound')}}
      </b-alert>
    </div>
    <div class="mt-1" v-else style="max-height: 250px;overflow: hidden">
      <b-skeleton-img></b-skeleton-img>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";

export default {
  name: "PlatformStackedChar",
  components: {VueApexCharts},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    incomesByPlatform: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    incomesByPlatform() {
      if (this.$refs['platformsBarChart'] != null) {
        this.$refs['platformsBarChart'].refresh()
      }
    }
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkMode']),
    chartOptions() {
      let $this = this
      return {
        chart: {
          id: 'platforms-bar-chart',
          stacked: true,
          stackType: '100%',
          foreColor: $this.isDarkMode ? '#fafafa' :'#373d3f'
        },

        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: $this.incomesByPlatform.map((checkin) => `${checkin.platform} (${checkin.count})`),
        },
        //Add red and green colors
        colors: ['#ff9f43', '#28c76f'],
        labels: $this.incomesByPlatform.map((checkin) => `${checkin.platform} (${checkin.count})`),
      }
    },
    series() {
      let $this = this;
      return [
        {
          name: "Commission",
          data: $this.incomesByPlatform.map((checkin) => checkin.commission)
        },
        {
          name: "Amount",
          data: $this.incomesByPlatform.map((checkin) => checkin.amount)
        }
      ];
    }
  },
  methods: {
    refresh() {
      if (this.$refs['platformsBarChart'] != null) {
        this.$refs['platformsBarChart'].refresh()
      }
    },
  }
}
</script>


<style scoped>

</style>
