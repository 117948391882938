<template>
<div>
  <calendar-bar v-model="dateRange" @input="refresh" from-key="start" until-key="end"
                :enable-custom="true"
                :active-options="['all', 'today', 'yesterday', 'thisWeek', 'lastWeek', 'last7Days', 'thisMonth', 'lastMonth', 'last30Days']"/>
  <b-row class="mb-1">
    <b-col cols="4"><building-select-box v-model="buildings" multiple clearable @input="refresh"/></b-col>
    <b-col cols="8"> <real-estate-picker  multiple v-model="properties" v-bind:buildings="buildings" clearable @input="refresh"/></b-col>
  </b-row>

  <!--Checkin, Checkout, Staying-->
  <b-row>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fas fa-sign-in-alt" v-bind:statistic="stats.checkin.length" v-bind:loading="loading.stats"
                                  :statistic-title="$t('contractStats.checkins')"
                                 icon-type="fontawesome" color="warning">
        <template v-slot:footer>
          <div style="min-height: 26px">
            <platform-logo v-for="(value, platform) in checkinByPlatforms" :key="platform"
                           :platform="platform" size="18px" color="primary" class="mr-1">
              <span class="text-secondary" style="font-size: 18px; margin-left: 10px">{{value}}</span>

            </platform-logo>
          </div>

        </template>
      </statistic-card-horizontal>
    </b-col>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fas fa-sign-out-alt"  v-bind:loading="loading.stats"
                                 :statistic-title="$t('contractStats.checkouts')"
                                 icon-type="fontawesome" color="warning">
        <template v-slot:footer>
          <div class="d-none d-lg-block" style="min-height: 26px">
            <platform-logo v-for="(value, platform) in checkoutByPlatforms" :key="platform"
                           :platform="platform" size="18px" color="primary" class="mr-1">
              <span class="text-secondary" style="font-size: 18px; margin-left: 10px">{{value}}</span>

            </platform-logo>
          </div>
        </template>
        <template #content-text>
          <span>{{stats.checkout.length}}</span>
          <span class="d-none d-lg-inline" v-if="stats.inRange > 0"> ({{ stats.inRange }} {{ $t('contractStats.checkins') }})</span>
        </template>
      </statistic-card-horizontal>

    </b-col>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fas fa-door-open" v-bind:statistic="stats.staying.length" v-bind:loading="loading.stats"
                                 :statistic-title="$t('contractStats.staying')"
                                 icon-type="fontawesome" color="warning">
        <template v-slot:footer>
          <div class="d-none d-lg-block" style="min-height: 26px">
            <platform-logo v-for="(value, platform) in stayingByPlatforms" :key="platform"
                           :platform="platform" size="18px" color="primary" class="mr-1">
              <span class="text-secondary" style="font-size: 18px; margin-left: 10px">{{value}}</span>

            </platform-logo>
          </div>
        </template>
      </statistic-card-horizontal>
    </b-col>
  </b-row>
  <!--Incomes, Expenses-->
  <b-row>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fa-solid fa-filter-circle-dollar" v-bind:statistic="incomes.internal || 0" post-fix=" €"
                                 formatNumber
                                 v-bind:loading="loading.stats"
                                 :statistic-title="$t('contractStats.internalIncome')"
                                 icon-type="fontawesome" color="success">
        <template v-slot:footer>
          <div class="d-none d-lg-block text-danger" style="min-height: 26px">
            <i class="fa-solid fa-circle text-danger"></i>
            {{ $t('contractStats.commissions') }}: {{ (expenses.internal.commission*-1) | numberFormatter }} €
          </div>
        </template>
      </statistic-card-horizontal>
    </b-col>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fa-solid fa-hand-holding-dollar" v-bind:statistic="incomes.external" post-fix=" €"
                                 formatNumber
                                 v-bind:loading="loading.stats"
                                 :statistic-title="$t('contractStats.externalIncome')"
                                 icon-type="fontawesome" color="success">
        <template v-slot:footer>
          <div class="d-none d-lg-block text-danger" style="min-height: 26px">
            <i class="fa-solid fa-circle text-danger"></i>
            {{ $t('contractStats.platformsCommissions') }}: {{ (expenses.external.commission*-1) | numberFormatter }} €
          </div>
        </template>

      </statistic-card-horizontal>
    </b-col>
    <b-col cols="12" lg="4">
      <statistic-card-horizontal icon="fa-solid fa-landmark" v-bind:statistic="incomes.total" post-fix=" €"
                                 formatNumber v-bind:loading="loading.stats"
                                 :statistic-title="$t('contractStats.totalIncome')"
                                 icon-type="fontawesome" color="success">
        <template v-slot:footer>
          <div class="d-none d-lg-block text-danger" style="min-height: 26px">
            <i class="fa-solid fa-circle text-danger"></i>
            {{ $t('contractStats.commissions') }}: {{ (expenses.total.commission*-1) | numberFormatter }} €
          </div>
        </template>
      </statistic-card-horizontal>
    </b-col>
  </b-row>

  <b-card>
    <h4>{{$t('contractStats.reservationTable')}}</h4>
    <contract-rent-stats-table v-bind:stats="stats" v-bind:loading="loading.stats"/>
  </b-card>

  <b-row style="min-height: 300px">
    <b-col cols="12" md="6" class="mb-1">
      <b-card style="height: 100%">
        <h4>{{ $t('contractStats.platforms') }}</h4>
        <span class="text-muted">{{$t('contractStats.calculatedByCheckin')}}</span>
        <platform-doughnut-chart v-bind:platforms-data="checkinByPlatform" v-bind:loading="loading.stats" />
      </b-card>
    </b-col>
    <b-col cols="12" md="6" class="mb-1">
      <b-card style="height: 100%">
        <h4>{{ $t('contractStats.incomesByPlatform') }}</h4>
        <span class="text-muted">{{$t('contractStats.calculatedByCheckout')}}</span>
        <platform-stacked-char v-bind:incomes-by-platform="incomesByPlatform"  v-bind:loading="loading.stats"/>
      </b-card>
    </b-col>
  </b-row>



</div>
</template>

<script>
import CalendarBar from "@/components/widgets/calendar/CalendarBar.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import {mapActions} from "vuex";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import ContractRentStatsTable from "@/components/panels/dashboard/contractRentStatsTable.vue";
import PlatformDoughnutChart from "@/components/chart/realEstates/contract/PlatformDoughnutChart.vue";
import PlatformStackedChar from "@/components/chart/realEstates/contract/PlatformStackedChar.vue";
import BuildingSelectBox from "@/components/components/picker/buildingSelectBox.vue";

const stats = ()=> ({checkin: [], checkout: [], inRange: 0, staying: [],cancelled: []})

export default {
  name: "contractRentDashboard",
  components: {
    BuildingSelectBox,
    PlatformStackedChar,
    PlatformDoughnutChart,
    ContractRentStatsTable, PlatformLogo, RealEstatePicker, StatisticCardHorizontal, CalendarBar},
  data: () => ({
    dateRange: {},
    buildings:[],
    properties:[],
    loading: {
      stats: true,
    },
    stats: stats(),
    statusNav: 'checkins'
  }),
  created() {
    this.$root.$on('crm::branch-updated', this.refresh);
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.refresh);
  },
  computed: {
    checkinByPlatforms() {
      return this.stats.checkin.reduce((acc, checkin) => {
        const platform = checkin.platform || 'Treasure Home';
        acc[platform] = acc[platform] || 0;
        acc[platform]++;
        return acc;
      }, {});
    },
    checkoutByPlatforms() {
      return this.stats.checkout.reduce((acc, checkout) => {
        const platform = checkout.platform || 'Treasure Home';
        acc[platform] = acc[platform] || 0;
        acc[platform]++;
        return acc;
      }, {});
    },
    stayingByPlatforms() {
      return this.stats.staying.reduce((acc, staying) => {
        const platform = staying.platform || 'Treasure Home';
        acc[platform] = acc[platform] || 0;
        acc[platform]++;
        return acc;
      }, {});
    },
    incomes(){
      let incomes = {internal: 0, external: 0, total: 0};
      if(this.stats.checkin.length === 0){
        return incomes;
      }
      this.stats.checkin.forEach(checkin => {
        if(checkin.platform == null || checkin.platform === 'Treasure Home'){
          incomes.internal += checkin.amount;
        }else{
          incomes.external += checkin.amount;
        }
        incomes.total += checkin.amount;
      });
      return incomes;
    },
    expenses(){
      let incomes = {internal: {commission:0, paymentProcessingFee:0}, external: {commission:0, paymentProcessingFee:0}, total: {commission:0, paymentProcessingFee:0}};
      if(this.stats.checkin.length === 0){
        return incomes;
      }
      this.stats.checkin.forEach(checkin => {
        if(checkin.isInternalIncome){
          incomes.internal.commission += checkin.commissionAmount || 0;
          incomes.internal.paymentProcessingFee += checkin.commissionAmount || 0;
        }else{
          incomes.external.commission += checkin.commissionAmount || 0;
          incomes.external.paymentProcessingFee += checkin.paymentProcessingFee || 0;
        }
        incomes.total.commission += checkin.commissionAmount;
        incomes.total.paymentProcessingFee += checkin.paymentProcessingFee;
      });
      return incomes;
    },
    checkinByPlatform(){
      let data = this.stats.checkin.reduce((acc, checkin) => {
        const platform = checkin.platform || 'Treasure Home';
        let platformStats = acc[platform];
        if (platformStats == null) {
          platformStats = acc[platform] = {platform: platform, count: 0};
        }
        platformStats.count++;
        return acc;
      }, {});
      return Object.values(data);
    },
    incomesByPlatform() {
      let data = this.stats.checkin.reduce((acc, checkin) => {
        const platform = checkin.platform || 'Treasure Home';
        let platformStats = acc[platform];
        if (platformStats == null) {
          platformStats = acc[platform] = {platform: platform, commission: 0, amount: 0, count: 0};
        }
        platformStats.amount = parseFloat((platformStats.amount + (checkin.amount || 0)).toFixed(2));
        platformStats.commission = parseFloat((platformStats.commission + (checkin.commissionAmount || 0)).toFixed(2));
        platformStats.count++;
        return acc;
      }, {});
      return Object.values(data);
    }
  },
  methods: {
    ...mapActions('realEstateContractsStats', ['getCheckinStats']),
    refresh() {
      this.loading.stats = true;
      this.getCheckinStats({...this.dateRange, buildings: this.buildings, properties: this.properties}).then(data => {
        console.log("getCheckinStats", data?.stats)
        this.stats = {...stats(), ...(data?.stats || {})};
        this.loading.stats = false;
      });
    }
  }
}
</script>



<style lang="scss">

</style>
